var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-4"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"sort-by":"date_count","sort-desc":true},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$route.name)+" "+_vm._s(_vm.typeName))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"","small":"","depressed":""},on:{"click":_vm.initialize}},[_vm._v("Refresh")]),(_vm.$store.state.currentPagePriv.create)?_c('v-btn',{attrs:{"color":"primary","small":"","depressed":""},on:{"click":_vm.createDO}},[_vm._v("Create")]):_vm._e()],1)]},proxy:true},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" Reset ")])]},proxy:true},{key:"item.type",fn:function(ref){return [_vm._v(" "+_vm._s(_vm.typeName)+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.total)?'Approved':'')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.$store.state.currentPagePriv.update)?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.$store.state.currentPagePriv.delete)?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteConfirmation(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Delete")])])]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"body-1 text-center"},[_vm._v("Anda akan menghapus stock opname ini?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItem}},[_vm._v("Yes")]),_c('v-spacer')],1)],1)],1),_c('InputStockOpnameFG',{ref:"refInputDO",on:{"refresh":_vm.initialize}}),_c('v-snackbar',{attrs:{"top":"","color":"green"},model:{value:(_vm.saved),callback:function ($$v) {_vm.saved=$$v},expression:"saved"}},[_vm._v("Data berhasil tersimpan!")]),_c('v-snackbar',{attrs:{"top":"","color":"red accent-2"},model:{value:(_vm.saveError),callback:function ($$v) {_vm.saveError=$$v},expression:"saveError"}},[_vm._v("Data gagal tersimpan! Mohon pastikan data yang akan disimpan sudah sesuai.")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
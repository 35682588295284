<template>
    <div class="pa-4">
      <v-data-table
          :headers="headers"
          :items="items"
          class="elevation-1"
          :search="search"
          sort-by="date_count"
          :sort-desc="true"
      >
          <template v-slot:top>
          <v-toolbar flat>
              <v-toolbar-title>{{$route.name}} {{typeName}}</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>                
              <v-spacer></v-spacer>
              <v-btn color="" small depressed @click="initialize" class="mr-2">Refresh</v-btn>
              <v-btn color="primary" small depressed @click="createDO" v-if="$store.state.currentPagePriv.create">Create</v-btn>
          </v-toolbar>
          </template>
          <template v-slot:no-data>
              <v-btn color="primary" @click="initialize">
                  Reset
              </v-btn>
          </template>
          <template v-slot:[`item.type`]="{  }">
            {{typeName}}
          </template>
          <template v-slot:[`item.total`]="{ item }">
            {{(item.total)?'Approved':''}}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">              
                <v-icon small @click="editItem(item)" v-bind="attrs" v-on="on" class="mr-2" v-if="$store.state.currentPagePriv.update">
                    mdi-pencil
                </v-icon>
              </template>
              <span>Edit</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">              
                <v-icon small @click="deleteConfirmation(item)" v-bind="attrs" v-on="on" class="mr-2" v-if="$store.state.currentPagePriv.delete">
                    mdi-delete
                </v-icon>
              </template>
              <span>Delete</span>
            </v-tooltip>

          </template>            

      </v-data-table>
      <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
          <v-card-title class="body-1 text-center">Anda akan menghapus stock opname ini?</v-card-title>
          <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItem">Yes</v-btn>
          <v-spacer></v-spacer>
          </v-card-actions>
      </v-card>
      </v-dialog>

      <InputStockOpnameFG ref="refInputDO" @refresh="initialize"></InputStockOpnameFG>
      <v-snackbar top v-model="saved" color="green">Data berhasil tersimpan!</v-snackbar>
      <v-snackbar top v-model="saveError" color="red accent-2">Data gagal tersimpan! Mohon pastikan data yang akan disimpan sudah sesuai.</v-snackbar>
    </div>
</template>

<script>
  import moment from 'moment'
  import InputStockOpnameFG from '../components/InputStockOpnameFG.vue'
  
  export default
  {
    components: { 
      InputStockOpnameFG
    }, 
    data: () => ({
      headers: [
        { text: 'Type', value: 'type' },
        { text: 'Tanggal', value: 'date_count' },
        { text: 'Status', value: 'total' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      items: [],
      search: '',
      saved: false,
      saveError: false,
      selectedItem: null,
      dialogDelete: false
    }),

    computed: {
      miscCodes() {
        return this.$store.state.misccode.objs
      },
      prodOrder() {
        return this.$store.state.deliveryorder.obj
      },
      typeName() {
        if (this.$route.params.type=='RM') return 'Bahan Baku'
        else if (this.$route.params.type=='LB') return 'Label & Botol'
        else return 'Finished Goods'
      }
    },

    watch: {
    },

    created () {
      this.$store.dispatch('ordercategory/getObjs')
      this.$store.dispatch('customer/getObjs')
      this.$store.dispatch('variant/getObjs')
      this.initialize()
    },
    filters: {
        moment: function (date) {
            return moment(date).format('D MMM YYYY');
        },
    },

    methods: {
      async initialize () {
        const sto = (await this.$store.dispatch('getObjs', {apidomain:'stockOpnames', parameter:''})).data.data
        this.items = sto.filter(el => {return el.type==this.$route.params.type.toUpperCase()})
        console.log(sto)
      },
      printDO(item) {
        console.log(item)
        this.$refs.refPrintDO.openDialog(item.do_no)
      },

      createDO() {
        this.$refs.refInputDO.date_count = null
        this.$refs.refInputDO.so_no = null
        this.$refs.refInputDO.openDialog()
      },
      deleteConfirmation(item) {
        console.log(item)
        this.selectedItem = item
        this.dialogDelete = true
      },
      async deleteItem () {
        const sto = (await this.$store.dispatch('stockopname/delObj', this.selectedItem))
        if (sto.status==200){
          this.$store.commit('setSnackbar', {saveSuccess: true, successMessage: "Data sudah terhapus."})
        }
        this.initialize()
        this.dialogDelete = false
      },
      editItem(item) {
        console.log(item)
        this.$refs.refInputDO.date_count = item.date_count
        this.$refs.refInputDO.so_no = true
        this.$refs.refInputDO.openDialog()
      }
    },
  }
</script>

<style>
.v-text-field fieldset, .v-text-field .v-input__control, .v-text-field .v-input__slot {
    border-radius: 7px!important;
}

</style>